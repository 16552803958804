.navbar-v1.affix,
.navbar-v1 {
	padding: 15px 0;
	position: fixed;
	width: 100%;
	top: 0;
	border-width: 0 0 1px;
	height: 76px;

	z-index: 50;
	box-shadow: 0 3px 3px -3px grey;
	background-color: var(--nav-bg-color);

	@media (max-width:768px) {
		position: absolute;
		height: 75px;
	}


	.fav-count {
		position: relative;

		.sub-count {
			-moz-border-radius: 50%;
			-webkit-border-radius: 50%;
			border-radius: 50%;
			display: block;
			position: absolute;
			top: 7px;
			width: 18px;
			height: 18px;
			background: var(--color-secondary);
			color: var(--color-white);
			text-align: center;
			font-size: 12px;
			line-height: 18px;

			@media (min-width:1400px) {
				right: 6px;
			}

			@media (min-width:1800px) {
				right: 10px;
			}

			@media (max-width:1399px) {
				right: 4px;
			}

			@media (max-width:1199px) {
				right: 2px;
			}

			@media (max-width:992px) {
				right: -2px;
			}


			@media (max-width:767px) {
				width: 15px;
				height: 15px;
				line-height: 15px;
				right: -1px;
			}
		}
	}

	.tele-box,
	.navbar-v1.affix .search-unit-ul,
	.navbar-v1.affix .tele-box {
		position: absolute;
		right: 0;
		top: 77px;
		background-color: var(--color-grey-light);
		max-width: 280px;
		padding: 8px 32px;
		height: 40px;
		font-weight: 600;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
	}

	.search-unit-ul {
		position: absolute;
		right: 0;
		background-color: var(--color-grey-light);
		top: 77px;
		max-width: 280px;
		padding: 8px 15px;
		z-index: 0;
	}

	.input-search-unit {
		width: 250px;


		.form-control {
			padding: 5px 10px;
			width: 200px;

		}

		.form-control::placeholder {
			font-size: 0.875rem;
			color: #aaa;
			font-style: italic;
		}

		.btn {
			border-radius: 0 5px 5px 0;
		}
	}

	.header-main {

		#logo {
			transition: all 0.3s ease;
			/* Füge eine Übergangsanimation hinzu */
		}

		.header-logo {
			position: absolute;
			z-index: 20;
			top: 15px;

			@media (max-width:992px) {
				top: 10px;
			}

			.logo {
				width: 80px;
				height: auto;

				@media (max-width:992px) {
					width: 57px;
				}


			}

		}

		.lang-dropdown {
			.dropdown-menu {
				overflow: hidden;
			}
		}

		.main-menu {
			float: right;

			@media (max-width:992px) {
				position: absolute;
				left: 0;
				top: 76px;
				background-color: var(--main-nav-mob-menu-bg-color);
				z-index: 5;
			}

			>ul {
				padding: 0;
				margin: 0;

				>li {
					float: left;
					display: inline-block;

					@media (min-width:992px) {
						border-radius: 6px;
					}

					@media (max-width:992px) {
						display: block;
						width: 100%;
					}

					&:last-child {
						margin-right: 0;
					}


					>a {
						color: var(--main-nav-font-color);
						text-transform: var(--main-nav-text-transform);
						font-size: var(--main-nav-font-size);
						padding: 15px 1.2vW;
						line-height: 1;
						display: block;
						font-weight: 400;

						@media (max-width: 991px) {
							font-size: var(--main-nav-font-size);
							color: var(--color-white);
						}

						@media (max-width:992px) {
							padding: 10px 15px;
							text-transform: capitalize;
							font-size: var(--main-nav-font-size);
							color: var(--main-nav-mob-font-color-hover);
						}


						&:hover {
							/*color: var(--main-nav-font-color-hover);
							background-color: var(--main-nav-bg-hover);*/
							text-decoration: none;
						}



					}

					&.dropdown {
						@media (min-width:992px) {
							-webkit-border-top-left-radius: 6px;
							-webkit-border-top-right-radius: 6px;
							-moz-border-radius-topleft: 6px;
							-moz-border-radius-topright: 6px;
							border-top-left-radius: 6px;
							border-top-right-radius: 6px;
						}

						cursor: pointer;

						&:hover {
							@media (min-width:992px) {

								.dropdown-menu {
									display: block
								}
							}
						}


						.dropdown-backdrop {
							display: none;
						}


						&.show {
							background-color: var(--main-nav-bg-hover);

							a {
								color: var(--main-nav-font-color-hover);
							}
						}


					}


					&:hover {
						@media (min-width: 993px) {
							color: var(--main-nav-font-color-hover);
							background-color: var(--main-nav-bg-hover);

							a {
								color: var(--main-nav-font-color-hover);
								text-decoration: none;
							}
						}




					}

				}
			}

			.btn {
				@media (max-width:340px) {
					font-size: 12px;
					padding: 8px 8px;
				}
			}
		}
	}

	.dropdown-menu {
		border-radius: 0;
		border: none;
		box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
		min-width: 200px;
		margin-top: -1px;

		@media (min-width:992px) {
			width: 100%;
			transform: none !important;
			z-index: 0;
			top: 43px !important;
			z-index: 1;
			min-width: 450px;
			background: var(--btn-primary-bg-color);
			border-radius: 8px;
			border-top-right-radius: 0;
			border-top-left-radius: 0;
		}



		@media (max-width: 992px) {
			width: 100% !important;
			max-height: 450px;
			overflow: scroll;
		}
	}

	.sub-nav {
		/*max-height: 350px;
		overflow: auto;*/

		position: relative;

		&:before {
			@media (min-width: 992px) {
				content: "";
				background: var(--btn-primary-bg-color);
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 5px;
			}
		}

		ul {
			list-style-type: none;
			padding-left: 10px;

			@media (min-width: 993px) {
				display: flex;
				flex-wrap: wrap;
				align-content: space-between;
				width: 100%;
				padding: 0;
				/*display: flex;
				flex-wrap: wrap;
				flex-direction: column;
				width: 50%;
				height: 263px;
				align-content: space-between;*/
				padding: 0;
			}

			@media (max-width: 992px) {
				width: 100%;
			}


			li {
				@media (min-width: 993px) {
					width: 100%;
					border-bottom: 1px solid rgb(255 255 255 / 20%);
					flex: 0 0 50%;
				}

				a {
					padding: 10px 20px;
					color: var(--color-white) !important;
					display: block;
					font-size: 15px;

					@media (max-width:992px) {
						padding: 10px;
						font-weight: 400;
						text-transform: initial;
					}

					&:hover {
						color: var(--color-white) !important;
						text-decoration: none;
						opacity: 0.7;

					}
				}

			}
		}


	}

	.icons-nav {
		display: flex;

		.lang-dropdown {
			#navlang {
				font-size: 14px;
				line-height: 14px;
				margin-top: 5px;
				text-decoration: none;
				cursor: pointer;

				vertical-align: inherit;

				img {
					margin-left: 3px;
					position: relative;
					top: -1px;
					height: 12px;
				}
			}

			.dropdown-menu {
				width: 100% !important;
				min-width: inherit;
				min-width: 90px;
				padding: 0 !important;
				position: absolute !important;
				top: 24px !important;
				border: 0;
				background: var(--color-white);
				border-radius: 0;


				@media screen and (max-width: 767px) {
					margin-top: 25px !important;
				}

				li {
					a {
						padding: 10px 5px;
						display: block;

						&:hover {
							text-decoration: none;
						}
					}
				}

				@media (max-width:991px) {
					width: 62px !important;
					border: 0;
					margin: 0;
					padding: 0 1.2vW !important;
					margin: 0 !important;
				}


				.en {
					.flag {
						background-image: url(RESOURCE/img/flag-en.png);
					}
				}

				.de {
					.flag {
						background-image: url(RESOURCE/img/flag-de.png);

					}
				}

				.lang-text {
					width: 20px;
					display: inline-block;
					color: #000;
					text-transform: uppercase;
					font-size: 14px;
				}

				span {
					width: 16px;
					height: 11px;
					background-repeat: no-repeat;
					display: inline-block;
					margin-left: 0;

				}
			}

			&:hover {
				.dropdown-menu {
					@media (min-width:992px) {
						display: block;
					}
				}

			}

		}


		@media (max-width:992px) {
			margin: 0;
			margin-right: 15px;
		}

		>li {
			>a {
				padding: 0 1.2vW;
			}
		}

		.fav-count {
			position: relative;

			.sub-count {
				-moz-border-radius: 50%;
				-webkit-border-radius: 50%;
				border-radius: 50%;
				display: block;
				position: absolute;
				top: -2px;
				right: -1px;
				width: 18px;
				height: 18px;
				background: var(--color-secondary);
				color: var(--color-white);
				text-align: center;
				font-size: 12px;
				line-height: 18px;

				@media (max-width:480px) {
					right: -3px;
				}

			}
		}
	}

	.icon {
		display: none;
		position: absolute;
		right: 10px;
		text-decoration: none;
		color: var(--color-secondary);
		font-size: 30px;

	}

	.navactive {
		@media (min-width:993px) {
			color: var(--main-nav-font-color-hover) !important;
			background-color: var(--main-nav-bg-hover) !important;
		}
	}

	.nav-row {
		@media (min-width:992px) {
			position: relative;
		}


		.col-12 {
			display: flex;
			justify-content: flex-end;

			.icons-nav {
				display: flex;
				align-items: center;
				margin-bottom: 0;
				position: relative;

				@media screen and (max-width: 992px) {
					position: absolute;
					right: 45px;
					top: 25px;
				}

				li {
					&.btn-call {
						cursor: pointer;

						a {
							&:hover {
								color: var(--color-grey-dark);
							}
						}

						@media screen and (max-width: 767px) {
							margin-left: 10px;
						}
					}

					a {
						padding: 6px 0.6vW;
						font-size: var(--main-nav-font-size);

						@media screen and (max-width: 767px) {
							padding: 6px 2vW;
							overflow: hidden;
						}
					}
				}
			}
		}
	}


	@media screen and (max-width: 992px) {

		.top-nav {
			padding: 10px 0 8px;
			text-align: right;


			.input-search-unit {
				display: none;
			}
		}

		.header-logo {
			padding-left: 0px !important;
		}

		.nav-row {
			min-height: 50px !important;
		}

		.icon {
			display: block;
			bottom: 10px;
		}

		.dropdown-menu {
			position: relative !important;
			transform: none !important;
			width: 65px;
			border-top: none;
			background: #49739d;
		}

		.main-menu {
			background-color: var(--nav-bg-color);
			padding: 0px 0;
			z-index: 50;
			display: none;
			width: 100%;
			border-bottom: 1px solid #61847a;
		}
	}

	@media screen and (max-width: 992px) {

		.top-nav {
			text-align: center;

			.input-search-unit {
				display: none;
			}
		}

		.header-main {
			.nav-row {
				min-height: 20px !important;
			}

			.icon {
				position: absolute;
				top: 11px;
				right: 10px;
			}
		}

	}

	@media screen and (min-width: 993px) {
		.main-menu {
			display: block !important;
		}
	}

	.nav-arrow {
		@media screen and (min-width: 992px) {
			position: absolute;
			right: 5px;
			top: 12px;
		}

		@media screen and (max-width: 991px) {
			position: absolute;
			right: 15px;
			top: 8px;
			color: #fff;
			width: 30px;
			text-align: center;
		}
	}
}

.to-top {
	position: fixed;
	cursor: pointer;
	bottom: 15px;
	display: none;
	right: 10px;
	width: 32px;
	height: 32px;
	border-radius: var(--border-radius);
	background-color: var(--color-orange);
	color: var(--color-white);
	z-index: 200;
	line-height: 32px;
	text-align: center;
	font-size: 24px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

}