.index-page-layout-v1 {
	margin-top: var(--page-margin-top);

	h2 {
		text-align: center;
	}

	.subtitle {
		text-align: center;
		text-indent: 0px;
	}

	.index-map {
		.leaflet-popup-content {
			margin: 0;
			padding: 15px;
		}
	}

	.highlights {
		display: flex;
		flex-wrap: wrap;

		.owl-dots {
			margin: 0;
			position: absolute;
			bottom: 60px;
			right: 0;
			left: 0;
		}

		.highlight {
			width: 25%;
			text-align: center;
			color: var(--color-black);
			padding: 10px;

			@media(max-width: 1199px) {
				width: 100%;
			}

			@media(max-width: 576px) {
				width: 100%;
			}

			.icon {
				margin-bottom: 10px;
				margin-top: 20px;
				font-size: 36px;
				color: var(--color-primary);
				position: relative;
			}

			.title {
				font-size: 20px;
				margin-bottom: 10px;
				font-weight: 600;
			}

			.text {
				font-size: 16px;
				padding-bottom: 50px;

				@media(max-width: 1199px) {
					padding-bottom: 80px;
				}
			}
		}
	}

	.index-regionen {

		.country {
			font-size: 22px;
			color: var(--color-secondary);
			padding: 12px 0 12px 75px;
			margin-top: 15px;
			margin-bottom: 15px;

			&.greece {
				background-image: url(RESOURCE/img/greece-flag-region.jpg);
				background-repeat: no-repeat;
			}

			&.cyprus {
				background-image: url(RESOURCE/img/cyprus-flag-region.jpg);
				background-repeat: no-repeat;
			}
		}

		.regions {
			-webkit-column-count: 4;
			-moz-column-count: 4;
			column-count: 4;

			@media (max-width:992px) {
				-webkit-column-count: 2;
				-moz-column-count: 2;
				column-count: 2;
			}


			@media (max-width:1100px) {
				-webkit-column-count: 3;
				-moz-column-count: 3;
				column-count: 3;
			}

			@media (max-width:576px) {
				-webkit-column-count: 1;
				-moz-column-count: 1;
				column-count: 1;
			}

			&.cyprus {
				-webkit-column-count: 1;
				-moz-column-count: 1;
				column-count: 1;
			}
		}


		.region-list {
			border-left: 1px solid #ddd;
			border-right: 1px solid #ddd;
		}

		.first-level {
			text-indent: 5px;
			font-size: 16px;
			font-weight: 600;
			border-top: 1px solid #ddd;
			border-bottom: 1px solid #ddd;
		}

		.second-level {
			padding-left: 30px;
			text-indent: 0px;
			font-size: 14px;
			font-weight: 600;
			border-top: 1px solid #ddd;
			text-indent: 0px;
		}

		.third-level {
			padding-left: 55px;
			font-size: 13px;
			font-weight: 400;
			border-top: 1px solid #ddd;
		}
	}

}