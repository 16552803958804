.unit-sub-title-v1 {
	padding-bottom: 20px;

	@media(min-width:768px) {
		display: flex;
		justify-content: space-between;
	}

	h1 {
		margin-bottom: 0px;
		padding-bottom: 0px;
	}

	.unit-subtitle {
		font-size: var(--font-size-md);
		padding-top: 10px;

		.fa,
		.fas,
		.far {
			width: 20px;
		}

		.type {
			color: var(--color-grey-dark);
		}

		.address {
			color: var(--color-grey-dark);
		}
	}

	.avg {

		@media(min-width:768px) {
			text-align: center;
		}

		@media(max-width:767px) {
			padding-top: 10px;
		}

		.avg-count {
			font-size: var(--font-size-md);
			display: flex;
			margin-top: 10px;
			justify-content: center;

			span {
				margin-left: 5px;
			}


		}
	}

	.avg-symbol {
		white-space: nowrap;
		display: inline-block;
		background-color: var(--bg-color-feedback);
		color: var(--font-color-feedback);
		padding: 5px;
		border-radius: var(--border-radius);
		font-weight: bold;
		font-size: 18px;
		cursor: pointer;
	}

	.rating {
		display: flex;
		flex-direction: column;
		align-items: end;
		font-size: 14px;
		margin-left: 15px;
		border: 1px solid var(--color-grey-normal);
		border-radius: var(--border-radius);
		padding: 15px;
		max-width: 290px;
		align-items: center;
		text-align: center;

		@media(max-width:767px) {
			max-width: 100%;
			margin-left: 0;
			margin-top: 15px;
		}

		.new-lable {
			font-weight: 700;
			font-size: 18px;
			color: var(--color-white);
			padding: 8px 17px;
			border-radius: 5px;
			border-radius: var(--border-radius);
			width: 100%;
			text-align: center;
			margin-bottom: 10px;
			background-color: var(--color-primary);
		}
	}
}