.search-result-header-v1 {
	position: relative;

	.found {
		font-weight: 400;
	}

	.srt-bl-top {
		.flex-align-center {

			.btn-filter {
				font-size: var(--font-size-md);
				padding-right: 10px;
				display: inline-block;
			}
		}
	}

	.srt-bl-top {
		padding-bottom: 10px;
	}

	.cta-search-bl {
		position: absolute;
		top: 0;
		right: 260px;

		ul {
			list-style: disc;
			padding-left: 15px;
		}

		button {
			margin: 0 5px;

			@media (max-width:479px) {
				margin: 0 0 10px;
			}
		}

		@media (max-width:991px) {
			position: unset;
			margin-bottom: 10px;
		}
	}

	.box {
		height: 450px !important;
	}
}