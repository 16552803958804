.footer-v1 {
	margin-top: 110px;

	.main-footer {
		background-color: var(--footer-bg-color);
		color: var(--footer-font-color);
		border-top: 1px solid var(--footer-border-top-color);

		.text-muted {
			color: var(--color-grey-normal) !important;

		}

		padding-left: 5vw;
		padding-right: 5vw;

		position: relative;

		.headline {
			font-size: 20px;
			color: var(--color-black);
			text-transform: uppercase;
			font-family: var(--title-font-family-main);

			@media (max-width: 992px) {
				word-break: break-word;
			}
		}


		.logo {
			width: 150px;
			height: auto;
			margin-top: 0;
			padding-bottom: 20px;
		}



		.openings {

			div {
				padding-top: 10px;
			}
		}

		a {
			color: var(--footer-font-color);
		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);
				}

				.fa,
				.fa-brands {
					font-size: 20px;
					color: var(--footer-font-color);

				}
			}

		}




		#button-addon1 {
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa,
			.fa-brands {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: var(--color-orange);
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}

		.paymentmethods {
			width: 100%;
			text-align: right;
			margin-top: 30px;

			.fa,
			.fa-brands {
				color: var(--color-white);
				font-size: 1.8rem;
				padding-left: 10px;

			}
		}

		img {
			max-width: 100%;
		}
	}

	.footer-bottom {
		padding: 30px 0 30px;
		color: var(--color-white);
		background: var(--color-primary);

		.container-xl {
			position: relative;
		}

		@media (max-width:1199px) {
			padding: 30px 0 30px;
		}

		p {
			margin: 0;
		}

		h5 {
			color: var(--color-white);
		}

		a {
			color: var(--color-white);
		}

		.footer-col {
			position: relative;
			padding-left: 40px;

			i {
				position: absolute;
				left: 0;
				top: 0;
				color: var(--color-white);
				height: 30px;
				width: 30px;
				background: #cbd4e9;
				display: flex;
				border-radius: 50%;
				align-items: center;
				justify-content: center;
				color: #0033a0;
			}
		}

		.social-icons {
			i {
				color: var(--color-white);
				font-size: 25px;

				@media (max-width:1199px) {
					font-size: 18px;
				}
			}
		}

		.footer-subnav {
			display: flex;
			align-items: center;
			margin: 0;

			@media (max-width:992px) {
				flex-direction: column;
				align-items: start;
			}

			li {
				margin-right: 30px;

				@media (max-width:992px) {
					margin-right: 0;
					margin-bottom: 20px;
				}

				.footer-col {
					padding-left: 40px;
				}



			}

			.social-icons {
				@media (min-width:768px) {
					position: absolute;
					right: 0;
					top: 0;
				}

				li {
					margin-right: 1rem;
				}
			}
		}
	}

	.fa-youtube {
		position: relative;
		height: 22px;
		width: 28px;

		&:before {
			content: "";
			background: url(RESOURCE/img/youtube.svg) no-repeat 0 0;
			height: 33px;
			width: 28px;
			background-size: cover;
			position: absolute;
			top: -6px;

			@media (max-width:1199px) {
				height: 23px;
				width: 20px;
				top: 0;
			}
		}
	}

	.newslatter-wraper {
		margin-top: -60px;
		padding: 0 20px;

		.headline {
			color: var(--color-white);
		}

		.newslatter-col {
			background: #0033a0;
			padding: 15px;
			border-radius: 10px;
			max-width: 1320px;
			width: 100%;
			margin: 0 auto;
			text-align: center;

			.input-bl {
				background-color: #cbd4e9;

				color: var(--white);
				border-radius: 30px;
				border: 4px solid #cbd4e9;
				padding-left: 15px;
				display: flex;
				max-width: 420px;
				margin: 0 auto;

				.btn-link {
					padding-left: 12px;
				}

				input {
					background: #cbd4e9;
				}

				.btn-link {
					padding: 12px 40px;
					border-radius: 30px;
					color: #fff;

					@media (max-width:767px) {
						padding: 6px 20px;
					}

					&:hover {
						background: #387fc5;
					}
				}

				.form-control {
					&::placeholder {
						font-size: 1rem;
						font-weight: 400;
						line-height: 1.5;
						color: #212529;
						font-style: normal;
					}
				}
			}

			.nl-answer {
				color: var(--color-white);
			}

		}
	}

	.list-unstyled {
		li {
			a {
				position: relative;
				padding-left: 15px;

				&:before {
					position: absolute;
					left: 0;
					top: 0px;
					content: "";
					font-weight: 300;
					font-family: "Font Awesome 6 Pro";
					line-height: 22px;
				}
			}
		}
	}
}

.v-office-logo {
	&:before {
		@media (min-width:768px) {
			content: "";
			background: #fff;
			position: absolute;
			left: -17px;
			width: 2px;
			height: 50px;
		}
	}

	background-color: var(--color-primary);
	padding: 10px 20px;
	text-align: right;

	@media (max-width:1199px) {
		position: absolute;
		text-align: right;
		background: transparent;
		top: 0px;
		right: 20px;
		width: 54px;
		padding: 0;
	}

	@media (max-width:992px) {
		bottom: 80px;
		right: 20px;
	}

	@media(min-width: 1200px) {
		text-align: right;
		padding: 0;
		position: absolute;
		top: -10px;
		right: 15px;
		width: 50px;
	}

	@media(max-width: 992px) {
		text-align: right;
	}

	@media(max-width: 767px) {
		position: absolute;
		bottom: 36px;
		right: 60px;
		text-align: left;
		padding-right: 15px;
		top: auto;
	}
}