.regionen-v1 {
	margin-top: var(--page-margin-top);
	padding-top: var(--page-padding-top);

	img {
		width: 100%;
	}

	.accordion-title {
		font-size: 16px;
		padding: 10px 3px;
		font-weight: 600;
		margin-bottom: 10px;
		margin-top: 10px;
		background-color: var(--color-secondary);
		color: var(--color-white);
		text-indent: 5px;
	}

	.reg-list {
		li {
			display: inline-block;
			width: 25%;

			@media(max-width: 768px) {
				width: 33.33%;
			}

			@media(max-width: 576px) {
				width: 50%;
			}
		}
	}

	.region-map {
		height: 350px;
	}
}