.blog-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.blog-entry {
		display: block;
		position: relative;
		margin-bottom: 30px;
		border-radius: var(--border-radius);
		text-decoration: none;
		overflow: hidden;
		box-shadow: 0 2px 20px 2px rgba(0, 0, 0, 0.1);

		.bl-dt {
			color: var(--color-white);
			background-color: var(--color-primary);
			padding: 3px 5px;
			display: inline-block;
			margin-bottom: 0;
			position: absolute;
			top: 10px;
			right: 10px;
		}

		&:hover {
			img {
				opacity: .5;
			}

			a {
				color: var(--color-white);
			}
		}

		img {
			width: 100%;
			left: 0;
			top: 0;
			z-index: 1;
			transition: all .25s ease 0s;
			height: 330px;
			object-fit: cover;


			@media(max-width: 992px) {
				height: 260px;
			}

			@media(max-width: 768px) {
				height: 180px;
			}

			@media(max-width: 576px) {
				height: 280px;
			}
		}


	}

	.blog-overlay-cont {
		position: relative;
		text-align: left;
		padding: 15px;
		background-color: var(--color-white);

		.h3 {
			color: var(--color-black);
			min-height: 60px;
			text-decoration: none;
		}

		.tags {
			padding: 0;
			margin-bottom: 0;

			li {
				display: inline-block;
				color: var(--color-black);
				background: var(--color-grey-light);
				list-style: none;
				padding-right: 10px;
				padding-left: 10px;
				margin-right: 5px;
				margin-bottom: 10px;
				padding-top: 10px;
				padding-bottom: 10px;
				/*border-radius: var(--border-radius);*/

				&:after {
					color: var(--color-black);
					position: relative;
					left: -3px;
				}

				a {
					color: var(--color-black);
				}
			}
		}
	}

	.blog-header {
		margin-bottom: 10px;
	}

	h2 {
		font-size: 24px;
		margin-top: 0;
	}


	.blogentry {
		padding-top: 0;
		margin-top: 15px;
		margin-bottom: 55px;

		&.inner-container {
			margin-top: 145px;
		}

		@media (max-width:992px) {
			margin-top: 70px;
			border-top: none;
		}

		img {
			width: 100%;
			height: auto !important;
		}

		.tag-box {
			padding: 0;
			margin: 0;

			li {
				display: inline-block;
				vertical-align: middle;
				margin: 0 5px 5px 0;

				a {
					background-color: #ddd;
					padding: 5px;
					display: block;

				}
			}
		}
	}
}