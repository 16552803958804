.contact-form-v1 {
	.checkbox label {
		vertical-align: inherit;
		padding-left: 25px;
	}

	.red {
		color: var(--color-red);
	}

	.privacy_ck {
		@media (max-width: 767px) {
			margin-bottom: 0 !important;
		}
	}
}