.contact-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.contact-form {
		.dlg-wraper {
			.box {
				height: 100vh;
			}
		}
	}
}