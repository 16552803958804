.ueber-uns-v1 {
	margin-top: var(--page-margin-top);

	.team-members {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(min(var(--about-us-grid-width), 100%), 1fr));
		grid-gap: 1em;
	}

	.member-info {
		text-align: center;
		background-color: var(--color-white);

		.name {
			font-weight: 600;
		}

		.resort {
			font-size: var(--font-size-md);
			color: var(--color-grey-dark);
			margin-bottom: 10px;
		}

		.phone {
			font-size: var(--font-size-md);
		}

		.email {
			font-size: var(--font-size-md);
		}

	}

	.team-members-container {
		display: flex;

		@media (max-width:767px) {
			flex-direction: column;
		}

		.member {
			width: 350px;
			background: var(--color-white);
			margin-right: 30px;
			max-height: 450px;

			@media (max-width:992px) {
				width: 200px;
				max-height: 330px;
			}

			@media (max-width:767px) {
				width: 100%;
				margin-right: 0;
				max-height: initial;
				margin-bottom: 30px;

			}
		}

		.team-info {
			width: calc(100% - 310px);

			@media (max-width:767px) {
				width: 100%;
			}
		}

		.team-avtar {
			display: inline-block;
			width: 280px;
			margin-right: 30px;

			@media (max-width:767px) {
				margin-bottom: 15px;
				margin-right: 0;
			}

			img {
				width: 100%;
			}
		}
	}


}