.static-view {
	margin-top: var(--page-margin-top);

	ul {
		list-style: disc;
		padding-left: 15px;
	}

	img {
		width: 100%;

		&.width-auto {
			@media (max-width:767px) {
				width: auto;
				margin: 0 auto;
				display: table;
			}
		}
	}

	.inner-banner {
		img {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			opacity: 0;
		}

		&.fluge {
			background-image: url(RESOURCE/img/flug-banner-new.webp);
		}

		&.reiseziele {
			background-image: url(RESOURCE/img/Banner_Reiseziele.webp);
		}

		&.hotel {
			background-image: url(RESOURCE/img/hotel-banner.webp);
		}

		&.mietwagen {
			background-image: url(RESOURCE/img/mietwagen-banner.webp);
		}

		&.fahren {
			background-image: url(RESOURCE/img/fahre_banner01.webp);
		}

		&.allgemeine {
			background-image: url(RESOURCE/img/banner_allgemeine.webp);
		}

		&.hotel {
			background-image: url(RESOURCE/img/hotel_Banner.webp);
		}

		&.mietwagen {
			background-image: url(RESOURCE/img/mietwagen_Banner.webp);
		}

		&.versicherung {
			background-image: url(RESOURCE/img/versicherung_Banner.webp);
		}

		&.familienurlaub {
			background-image: url(RESOURCE/img/Familie_Banner.webp);
		}

		&.newsletter {
			background-image: url(RESOURCE/img/newsletter_Banner.webp);
		}

		&.contact {
			background-image: url(RESOURCE/img/banner_agb.webp);
		}

		&.agb {
			background-image: url(RESOURCE/img/banner_agb.webp);
		}

		&.FurVermieter {
			background-image: url(RESOURCE/img/vermieter_Banner.webp);
		}

		&.blog {
			background-image: url(RESOURCE/img/blog_banner.webp);
		}

		&.strandurlaub {
			background-image: url(RESOURCE/img/strandurlaub_banner.webp);
		}

		&.VillaPool {
			background-image: url(RESOURCE/img/villaPool_banner.webp);
		}

		&.carental {
			background-image: url(RESOURCE/img/MietwagenBanner.webp);
		}

		&.vermieteranfrage {
			background-image: url(RESOURCE/img/vermieteranfrage_banner.webp);
		}
	}

	&.mietwagen-us-v1 {
		.logo-bl {
			display: flex;

			a {
				margin-right: 35px;
			}

			img {
				margin-bottom: 16px;
				max-width: 50%;
			}
		}
	}

	&.Mietwagen {
		.logo-bl {
			display: flex;
			flex-direction: column;
			position: relative;
			padding: 15px 0;
			height: 100%;

			@media (max-width:767px) {
				padding: 0 0 30px;
				margin: 0 0 30px;
				height: auto;
			}

			&:before {
				content: "";
				background: var(--color-grey-normal);
				position: absolute;
				height: 100%;
				right: 0;
				top: 0;
				height: 100%;
				width: 1px;

				@media (max-width:767px) {
					top: auto;
					height: 1px;
					width: 100%;
					bottom: 0;
				}

			}

			a {
				margin-right: 35px;

				@media (max-width:767px) {
					margin-right: 0;
				}
			}

			img {
				margin-bottom: 0;
				max-width: 100%;

			}
		}

		.flex-row-reverse {
			.logo-bl {
				&:before {
					right: auto;
					top: 0;
					left: -20px;

					@media (max-width:767px) {
						right: auto;
						top: auto;
						left: 0;
						bottom: 0;
					}
				}
			}

		}

		.table-wraper,
		.mietwagen-block {
			border: 1px solid var(--color-grey-normal);
			padding: 15px;
			border-radius: var(--btn-border-radius);

			@media (max-width:767px) {
				margin: 0 0 30px;
			}
		}
	}

	.booking-bl {
		z-index: 0;
	}

	.fl-left {
		float: left;
		max-width: 250px;
	}

	.img-border {

		border: 1px solid #f3f3f3;
		overflow: hidden;
		border-radius: 4px;
		margin-right: 15px;
	}

	.logo-bl {
		display: flex;

		a {
			margin-right: 35px;
		}

		img {
			margin-bottom: 16px;
			max-width: 330px;
		}
	}

	.star-rating {
		i {
			color: #0033a0;
		}
	}

	.langauge-menu {
		text-align: right;

		select {
			border: 1px solid #000;
			padding: 10px 15px;
			outline: none;
		}
	}

	.date-time-box {
		display: flex;
		width: 50%;

		@media (max-width:767px) {
			display: flex;
			width: 100%;
			flex-direction: column;
		}

		.date-box {
			width: 50%;

			@media (max-width:767px) {

				width: 100%;
				margin-bottom: 15px;

			}
		}

		.time-box {
			display: flex;
			align-items: center;
		}

		.small-label,
		.last-label {
			margin: 0 10px;
		}
	}

	.star-rating {
		img {
			width: auto;
		}
	}

	&.reiseziele-v1 {
		.index-regionen {

			.country {
				font-size: 22px;
				color: var(--color-secondary);
				padding: 12px 0 12px 75px;
				margin-top: 15px;
				margin-bottom: 15px;

				a {
					display: inline-block;
				}

				&.greece {
					background-image: url(RESOURCE/img/Flag_of_Greece.svg);
					background-repeat: no-repeat;
					background-size: contain;
					padding-left: 90px;
					background-size: 40px;
					background-position: 22px 15px;
				}

				&.cyprus {
					background-image: url(RESOURCE/img/Flag_of_Cyprus.svg);
					background-repeat: no-repeat;
					background-size: contain;
					padding-left: 100px;
				}
			}

			.regions {
				-webkit-column-count: 4;
				-moz-column-count: 4;
				column-count: 4;

				@media (max-width:992px) {
					-webkit-column-count: 2;
					-moz-column-count: 2;
					column-count: 2;
				}


				@media (max-width:1100px) {
					-webkit-column-count: 3;
					-moz-column-count: 3;
					column-count: 3;
				}

				@media (max-width:576px) {
					-webkit-column-count: 1;
					-moz-column-count: 1;
					column-count: 1;
				}

				&.cyprus {
					-webkit-column-count: 1;
					-moz-column-count: 1;
					column-count: 1;
				}
			}


			.region-list {
				/*border-left: 1px solid #ddd;
				border-right: 1px solid #ddd;*/
				background: #fbfbfb;
				border-radius: 8px;
				margin-bottom: 15px;
			}

			.first-level {
				text-indent: 5px;
				font-size: 16px;
				font-weight: 600;
				padding: 10px 15px;
				/*border-top: 1px solid #ddd;
				border-bottom: 1px solid #ddd;*/
			}

			.second-level {
				padding-left: 20px;
				text-indent: 0px;
				font-size: 14px;
				font-weight: 600;
				/*border-top: 1px solid #ddd;*/
				text-indent: 0px;
				padding-bottom: 8px;
				padding-top: 8px;
			}

			.third-level {
				padding-left: 35px;
				font-size: 13px;
				font-weight: 400;
				/*border-top: 1px solid #ddd;*/
				padding-top: 10px;
				padding-bottom: 10px;

				&:before {

					content: "\f054";
					font-family: 'Font Awesome 6 Pro';
					font-size: 12px;
					margin-right: 4px;

				}
			}
		}
	}

	label {
		margin-bottom: 5px;

		@media (max-width:767px) {
			margin-bottom: 5px;
			margin-top: 15px;
		}
	}

	&.fluge-us-v1 {
		.sponsored-logo {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			border: 1px solid #e5e2e2;
			border-radius: 4px;

			@media (max-width:767px) {
				max-width: 336px;
				margin: 20px auto 0;
				height: auto;
			}

			img {
				width: auto;

				@media (max-width:991px) {
					width: 100%;
				}
			}
		}

	}

	.position-relative {
		position: relative;
	}

	.arrow-down {
		position: absolute;
		right: 25px;
		top: 10px;
		pointer-events: none;

		&:before {
			content: "\f107";
			font-family: "Font Awesome 6 Pro";
			position: absolute;
			font-size: 15px;
			height: 50px;
			width: 50px;
			left: 0;
			top: 0;

			display: inline-block;
		}
	}

	.btn-bl {
		display: flex;
		align-items: center;

		@media (max-width:767px) {
			flex-direction: column;
		}

		.btn {
			margin-right: 10px;

			@media (max-width:767px) {
				margin-right: 0;
				margin-bottom: 15px;
			}
		}
	}

	.checkbox {
		label {
			@media (max-width:767px) {
				margin-bottom: 10px;
				margin-top: 0;
				padding-left: 25px;
			}

			&:after,
			&:before {
				margin-left: 0;
			}

		}

		input[type=checkbox] {
			position: absolute;
		}
	}

	.img-aligment {
		max-width: 550px;
		margin-bottom: 15px;

		@media (max-width:992px) {
			max-width: 100%;
		}

		&.fl-right {
			float: right;
			margin-left: 35px;
		}

		&.fl-left {
			float: left;
			margin-right: 35px;
		}

	}

	.PflichtfelderInq {
		.checkbox-success input[type=checkbox]:checked+label:after {

			left: 22px;
		}
	}

}

.contact-page {
	.no-gutters {
		@media (max-width:767px) {
			margin-bottom: 0 !important;
		}
	}

	.arrow-down {
		background-image: none;
		right: 0;
		top: 4px;

		&:before {
			content: "\f107";
			font-family: Font Awesome\ 6 Pro;
			position: absolute;
			font-size: 15px;
			height: 20px;
			width: 18px;
			left: 0;
			top: 0;
		}
	}

}

.language-bl {
	button {
		background-color: transparent !important;
		color: #000 !important;
		padding-right: 20px;

		img {
			width: 20px;
			position: absolute;
			top: 14px;
			right: 10px;
		}
	}

	.dropdown-menu {

		padding: 0;
		min-width: auto;
		list-style: none;

		li {
			padding-right: 20px;

			a {
				padding: 5px 14px 5px 10px;
				display: inline-block;
				min-width: 91px;

				&:hover {
					background: transparent;
					text-decoration: none;
				}
			}

			img {
				width: 20px;
				position: absolute;
				top: 10px;
				right: 10px;
			}
		}
	}
}