.blogentry-v1 {
	margin-top: var(--page-margin-top);

	.img-thumbnail {
		height: 100% !important;
	}


	.img-col {
		position: relative;

		.percent {
			position: absolute;
			left: 0px;
			font-size: 20px;
			font-weight: bold;
			color: var(--color-white);
			background-color: var(--color-red);
			padding: 10px;
		}
	}

	.tags {
		padding: 0;

		li {
			display: inline-block;
			margin-right: 5px;

			.btn {
				color: var(--color-black);
				background: var(--color-grey-light);
				border-radius: 0;
				border: 0;

				&:hover {
					color: var(--color-black);
					background: var(--color-grey-light);
					border-color: var(--color-grey-light);
				}
			}

		}
	}

	.date {
		color: var(--color-white);
		background-color: var(--color-primary);
		padding: 3px 5px;
	}


	.offer-title {
		padding: 10px 0px;
		font-size: 1.2rem;
		font-weight: bold;
	}

	.blogcontent {
		img {
			width: 100%;
		}
	}

	.owl-carousel {
		@media(min-width: 768px) {
			display: flex;
			width: unset;

			.unit-card-v1 {
				@media(min-width: 768px) {
					margin-bottom: 25px;
				}
			}
		}

		.owl-dots {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0;

			.owl-dot {
				width: 15px;
				height: 15px;
				background: #b5b5b5;
				margin: 0 5px;
				border-radius: 100px;

				&.active {
					background: var(--color-primary);
				}
			}
		}
	}

	.btn-line {
		@media(max-width: 767px) {
			margin-top: 40px !important;
		}
	}

	table {
		tr {
			th {
				background: var(--color-primary-light);
				border: 1px solid var(--color-secondary-light);

				padding: 10px;
				color: #000;
			}

			td {
				border: 1px solid var(--color-secondary-light);
				padding: 10px;
			}
		}
	}
}